import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import how_1 from "../assets/img/how_1.png";
import how_2 from "../assets/img/how2.png";
import how_3 from "../assets/img/how3.png";
import online1 from "../assets/img/online1.png";
import online2 from "../assets/img/online2.png";

import online3 from "../assets/img/online3.png";

import { offline, online } from "../helper/HowItWorks";
export const HowitWorks = () => {
  const [activebutton, setactivebutton] = useState(1);
  const [offlineval, setofflineval] = useState(offline["step1"]);
  return (
    <div className="HowitWorks common_width">
      <div className={`left_side `}>
        <div className="buttons_wrapper">
          <button
            className={`${activebutton == 1 && "active"}`}
            onClick={(e) => setactivebutton(1)}
          >
            Offline
          </button>
          <button
            className={`${activebutton == 2 && "active"}`}
            onClick={(e) => setactivebutton(2)}
          >
            Online
          </button>
        </div>

        <>
          <h1>How it works</h1>
          {offlineval["content"].map((Each, key) => (
            <p key={key}>
              {Each.number}. {Each.text}
            </p>
          ))}
        </>

        <a href="#pricing_wrapper_section">Reserve your spot</a>
      </div>

      {activebutton == 1 && (
        <div className="how_slides_area">
          <Splide
            onMounted={(e) => {
              setofflineval(offline["step1"]);
            }}
            onMove={(newIndex, prevIndex, destIndex) => {
              if (prevIndex == 0) {
                setofflineval(offline["step1"]);
              } else if (prevIndex == 1) {
                setofflineval(offline["step2"]);
              } else if (prevIndex == 2) {
                setofflineval(offline["step3"]);
              }
            }}
            options={{
              pagination: false,
            }}
          >
            <SplideSlide>
              <img src={how_1} alt="1" />
            </SplideSlide>
            <SplideSlide>
              <img src={how_2} alt="1" />
            </SplideSlide>
            <SplideSlide>
              <img src={how_3} alt="1" />
            </SplideSlide>
          </Splide>
        </div>
      )}

      {activebutton == 2 && (
        <div className="how_slides_area">
          <Splide
            onMounted={(e) => {
              setofflineval(online["step1"]);
            }}
            onMove={(newIndex, prevIndex, destIndex) => {
              if (prevIndex == 0) {
                setofflineval(online["step1"]);
              } else if (prevIndex == 1) {
                setofflineval(online["step2"]);
              } else if (prevIndex == 2) {
                setofflineval(online["step3"]);
              }
            }}
            options={{
              pagination: false,
            }}
          >
            <SplideSlide>
              <img src={online1} alt="1" />
            </SplideSlide>

            <SplideSlide>
              <img src={online3} alt="1" />
            </SplideSlide>
            <SplideSlide>
              <img src={online2} alt="1" />
            </SplideSlide>
          </Splide>
        </div>
      )}
    </div>
  );
};
