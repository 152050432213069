import React from "react";

export const PricingCard = ({
  type,
  heading,
  cpn,
  stores,
  commision,
  pd,
  dv,
  p,
  w,
  setPackage,
  setForm,
}) => {
  return (
    <div className="PricingCard" id={type == "Advance" && "border_add"}>
      <p>{type}</p>
      <h1>{heading}</h1>

      <ul>
        <li>{cpn} coupons distributed</li>
        <li>{stores} stores</li>
        <li>{commision}(%) Sales Commision</li>
        <li>
          {pd == true ? (
            <i
              class="fa-solid fa-circle-check"
              style={{ color: "#6BB74A", marginRight: 10 }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-circle-xmark"
              style={{ color: "#ED1516", marginRight: 10 }}
            ></i>
          )}
          Partner’s Dashboard
        </li>
        <li>
          {dv == true ? (
            <i
              class="fa-solid fa-circle-check"
              style={{ color: "#6BB74A", marginRight: 10 }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-circle-xmark"
              style={{ color: "#ED1516", marginRight: 10 }}
            ></i>
          )}
          Distribution via preferred stores
        </li>
        <li>
          {p == true ? (
            <i
              class="fa-solid fa-circle-check"
              style={{ color: "#6BB74A", marginRight: 10 }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-circle-xmark"
              style={{ color: "#ED1516", marginRight: 10 }}
            ></i>
          )}
          Product /Category wise coupons
        </li>
        <li>
          {w == true ? (
            <i
              class="fa-solid fa-circle-check"
              style={{ color: "#6BB74A", marginRight: 10 }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-circle-xmark"
              style={{ color: "#ED1516", marginRight: 10 }}
            ></i>
          )}
          Wallet
        </li>
      </ul>

      <button
        onClick={(e) => {
          setPackage(type);
          setForm(true);
        }}
      >
        Apply now
      </button>
    </div>
  );
};
