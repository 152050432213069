import React, { useState } from "react";
import { Header } from "../components/Header";
import "../landing.css";
import scrollDown from "../assets/img/scroll_down.svg";
import { EasyServices } from "../components/EasyServices";
import { HowitWorks } from "../components/HowitWorks";

// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import { AcceptingBusiness } from "../components/AcceptingBusiness";
import { Brands } from "../components/Brands";
import { GetNews } from "../components/GetNews";
import { Faq } from "../components/Faq";
import { Pricing } from "../components/Pricing";
import { Footer } from "../components/Footer";
import main_bg from "../assets/img/main_bg-min.jpg";
import { Form } from "../components/Form";

export const Landing = () => {
  const [FormState, setForm] = useState(false);
  const [Packge, setPackage] = useState("");

  return (
    <div>
      <main>
        <img src={main_bg} alt="" />
        <Header />
        <div className="main_content_area common_width">
          <div className="presentation">
            <h1>Grow Ecom/In-Store sales 10X</h1>
            <p>
              Be the part of Inburg's merchant network that brings customers
              using the disruptive coupons distribution system
            </p>

            <div className="input_wrapper">
              {/* <input
                type="text"
                placeholder="Enter email address
"
              /> */}
              <a href="#pricing_wrapper_section">Apply</a>
            </div>
          </div>

          <a href="#">
            <img src={scrollDown} alt="" />
          </a>
        </div>
      </main>

      {/* Easu Service */}
      <EasyServices />

      {/* how it works */}
      <HowitWorks />

      {/* AcceptingBusiness */}

      <AcceptingBusiness />

      {/* Brands on board */}
      <Brands />

      {/* get news */}
      <GetNews />

      {/* Faq */}
      <Faq />

      {/* Pricing */}
      <Pricing setForm={setForm} setPackage={setPackage} />

      {FormState && <Form setForm={setForm} Packge={Packge} />}

      {/* Footer */}
      <Footer />
    </div>
  );
};
