import React from "react";
import { AcceptBox } from "./AcceptBox";
import accessories from "../assets/img/accessories.svg";
import ap1 from "../assets/img/ap1.svg";
import ap2 from "../assets/img/ap2.svg";
import ap3 from "../assets/img/ap3.svg";
import ap4 from "../assets/img/ap4.svg";
import ap5 from "../assets/img/ap5.svg";
import ap6 from "../assets/img/ap6.svg";
import ap7 from "../assets/img/ap7.svg";

export const AcceptingBusiness = () => {
  return (
    <div className="AcceptingBusiness common_width">
      <h1>Accepting businesses</h1>

      <div className="boxes_accept">
        <AcceptBox text="Apparel & Accessories" icon={accessories} />
        <AcceptBox text="Electrical & Electronics" icon={ap1} />
        <AcceptBox text="Travel & Tourism" icon={ap2} />
        <AcceptBox text="Art Galleries" icon={ap3} />
        <AcceptBox text="Restaurants & Bars" icon={ap4} />
        <AcceptBox text="Furniture & Furnishings" icon={ap5} />
        <AcceptBox text="Beauty & Make-up" icon={ap6} />
        <AcceptBox text="Footwear" icon={ap7} />
      </div>
    </div>
  );
};
