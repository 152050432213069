import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const CancellationPolicy = () => {
  return (
    <div className="Privacy">
      <Header />

      <div className="common_width">
        <h1>Cancellation & Refund</h1>
        <p>
          As a policy, orders once placed cannot be cancelled or modified. Each merchant/store in the Inburg merchants network has its own policies regarding Cancellation & Refunds. However, most policies are according to market standards only. We strongly recommend you go through the policies of the respective stores or speak to the in-store sales assistance desk before purchasing their products.
        </p>

        <p>
          In the rare chance that the product you have ordered is not available with the seller, you are advised to speak to the respective seller’s support team to help you with some alternative options. If they are to your liking, possibly can modify your order for you. If not, you can raise a refund request with the seller directly.
        </p>

        <p>In case you need to raise a support ticket against any purchase feel free to send an email to support@inburg.com</p>

      </div>
      <Footer />
    </div>
  );
};
