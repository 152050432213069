import React from "react";
import { FaqQuestion } from "./FaqQuestion";

export const Faq = () => {
  return (
    <div className="Faq common_width">
      <h1>Frequently asked questions</h1>
      <FaqQuestion
        heading="Is there any fee for merchants to join the network?"
        ans="Inburg is a premium merchants network. Joining the network is free but the subscription plans decide how often you or your coupons are seen by customers."
      />
      <FaqQuestion
        heading="How do coupons are created?"
        ans="When you sign up as a merchant, you name your store, the number of stores, and the categories of products you deal with and enter the discount coupons denomination based on the sub-categories you deal with. Finally, you allow Inburg to create, delete, update, and manage coupons on behalf of your store(s)."
      />
      <FaqQuestion
        heading="How many times my store coupons are shown to customers?"
        ans="This depends on a number of factors. We can surely speak about the two critical ones. First, your subscription plan. Second positive signals"
      />
      <FaqQuestion
        heading="What are positive signals?"
        ans="Positive signals are our proprietary algorithm that checks some important signals like coupon generation rate, coupon acceptance rate, number of times Inburg’s QR code scanned, etc.        "
      />

      <FaqQuestion
        heading="How do you rank brands/coupons of the same categories? "
        ans="Again, this depends on a number of factors. We can surely speak about the two critical ones.  First, your subscription plan. Second positive signals"
      />

      <FaqQuestion
        heading="Can we be transparent about any charges involved?"
        ans="-Sure, apart from monthly recurring fees aka subscription to be part of the network (remember, you can continue as a free merchant forever) we do charge a small sales convenience fee on each successful redemption of the coupons. Please check the pricing section of this page for the convenience fee structure. Got more questions? Feel free to reach out to us at partners@inburg.com"
      />
    </div>
  );
};
