import React from "react";

export const ServiceCard = ({ img, heading, text }) => {
  return (
    <div className="ServiceCard">
      <img src={img} alt="" />
      <h1>{heading}</h1>
      <p>{text}</p>
    </div>
  );
};
