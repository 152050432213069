import React from "react";
import phone from "../assets/img/phone.svg";
import chat from "../assets/img/chat.svg";
import logo from "../assets/img/Inburg-Logo.png";
import linkedin from "../assets/img/linkedin.svg";
import insta from "../assets/img/insta.svg";

export const Header = () => {
  return (
    <header className="common_width">
      <nav>
        {/* <li>
          <a
            href="tel:+1 21314132"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={phone} alt="Phone" />
            <p style={{ marginLeft: 10 }}>+1 21314132</p>
          </a>
        </li>
        <li>
          <img src={chat} alt="Chat" />
          <p>Chat now</p>
        </li> */}
      </nav>

      <img src={logo} alt="Logo" />

      <nav>
        <li>
          <a href="#">
            <img src={linkedin} alt="Linkedin" />
          </a>
        </li>
        <li>
          <a href="#">
            <img src={insta} alt="instagram" />
          </a>
        </li>
      </nav>
    </header>
  );
};
