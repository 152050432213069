import React from "react";
import logo from "../assets/img/logo.svg";

import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <div className="Footer common_width">
      <div className="col">
        <img src={logo} alt="" />
        <p>
          A patent-pending merchants network that helps merchants gain new
          customers and retain them economically, and efficiently.
        </p>
      </div>
      <ul>
        <li className="head">
          <Link to="/">Copyright</Link>
        </li>
      </ul>

      <ul>
        <li className="head">
          <Link to="/Privacy">Privacy</Link>
        </li>
      </ul>

      <ul>
        <li className="head">
          <Link to="/Agreement">User Agreement</Link>
        </li>
      </ul>

      <ul>
        <li className="head">
          <Link to="/cancellation-refund-policy">Cancellation & Refund </Link>
        </li>
      </ul>

      <ul>
        <li className="head">
          <Link to="/shipping-delivery-policy">Shipping & Delivery </Link>
        </li>
      </ul>

      <ul>
        <li className="head">
          <a href="/contact-inburg">Contact</a>
        </li>
      </ul>

      <p style={{ marginTop: "2rem" }}>© {new Date().getFullYear()} Inburg India Private Limited  All rights reserved</p>
    </div>
  );
};
