import React from "react";
import CloseIcon from "@mui/icons-material/Close";
export const Form = ({ setForm, Packge }) => {
  // https://script.google.com/macros/s/AKfycbw_1VDZkwyN4r5ZCOi6gRJZRnbVyWdv-rcMzMJVLpESRSwbh_5J9xHRcbf2Gu4BHSyxjQ/exec
  return (
    <div
      className="FormWrapper"
      onClick={(e) => {
        if (e.target.classList[0] == "FormWrapper") {
          setForm(false);
        }
      }}
    >
      <form
        id="form_excel"
        onSubmit={(e) => {
          const scriptURL =
            "https://script.google.com/macros/s/AKfycbw_1VDZkwyN4r5ZCOi6gRJZRnbVyWdv-rcMzMJVLpESRSwbh_5J9xHRcbf2Gu4BHSyxjQ/exec";
          e.preventDefault();
          const form = document.querySelector("#form_excel");

          fetch(scriptURL, { method: "POST", body: new FormData(form) })
            .then((response) => {
              setForm(false);
              console.log("Success!", response);
            })
            .catch((error) => console.error("Error!", error.message));
        }}
      >
        <span
          className="close_icon"
          onClick={(e) => {
            setForm(false);
          }}
        >
          <CloseIcon />
        </span>
        <h1>You are applying for {Packge} Package</h1>
        <div className="input_grid">
          <input type="hidden" name="Package opted" value={Packge} />
          <div className="input_wrapper">
            <label htmlFor="CompanyName">Company Name</label>
            <input
              type="text"
              id="CompanyName"
              name="Company Name"
              placeholder="Enter Company Name"
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="CompanyWebsite">Company Website</label>
            <input
              type="text"
              placeholder="Enter Company Name"
              id="CompanyWebsite"
              name="Company Website"
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              placeholder="Enter Your Name"
              name="Name "
              id="Name"
            />
          </div>
          <div className="input_wrapper">
            <label htmlFor="Email">Email</label>
            <input
              type="text"
              placeholder="Enter Your Email"
              name="Email"
              id="Email"
            />
          </div>
          <button
          // onClick={(e) => {
          //   setForm(false);
          // }}
          >
            Apply now
          </button>
        </div>
      </form>
    </div>
  );
};
