import React from "react";
import { PricingCard } from "./PricingCard";

export const Pricing = ({ setForm, setPackage }) => {
  return (
    <div
      className="AcceptingBusiness Pricing common_width"
      id="pricing_wrapper_section"
    >
      <h1>Pricing </h1>
      <p>Introductory pricing may surge post launch of the network</p>

      <div className="pricing_cards">
        <PricingCard
          setForm={setForm}
          setPackage={setPackage}
          type="Free"
          heading="₹0.00"
          cpn="50"
          stores="1"
          commision="12.5"
          pd={true}
          dv={false}
          p={false}
          w={true}
        />
        <PricingCard
          type="Starter"
          heading="₹2,999"
          cpn="300"
          stores="up to 3"
          setPackage={setPackage}
          commision="10"
          pd={true}
          dv={false}
          setForm={setForm}
          p={false}
          w={true}
        />
        <PricingCard
          type="Advance"
          heading="₹3,999"
          cpn="750"
          stores="up to 5"
          commision="7"
          pd={true}
          setForm={setForm}
          setPackage={setPackage}
          dv={true}
          p={false}
          w={true}
        />
        <PricingCard
          type="Elite"
          heading="₹4,999"
          cpn="3000"
          stores="up to 10"
          commision="3"
          setPackage={setPackage}
          pd={true}
          dv={true}
          setForm={setForm}
          p={true}
          w={true}
        />
      </div>
    </div>
  );
};
