import React from "react";

export const AcceptBox = ({ icon, text }) => {
  return (
    <div className="AcceptBox">
      <img src={icon} alt="" />
      <p>{text}</p>
    </div>
  );
};
