import "./App.css";
import { Landing } from "./pages/Landing";
import { Routes, Route, useLocation } from "react-router-dom";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";
import { CancellationPolicy } from "./pages/CancellationPolicy";
import { ShippingPolicy } from "./pages/ShippingPolicy";
import { Contact } from "./pages/Contact";
import { useEffect } from "react";
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Agreement" element={<Terms />} />
        <Route path="/cancellation-refund-policy" element={<CancellationPolicy />} />
        <Route path="/shipping-delivery-policy" element={<ShippingPolicy />} />
        <Route path="/contact-inburg" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
