import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const ShippingPolicy = () => {
  return (
    <div className="Privacy">
      <Header />

      <div className="common_width">
        <h1>Shipping & Delivery</h1>
        <p>
          Inburg is a merchant network/marketplace where independent merchants/sellers and buyers connect and transact with each other either via online e-commerce or physical stores. Inburg does not make, own, sell or ship any of the products listed on the website or displayed in-store. Each product you purchase via the Inburg QR code is delivered in hand at the physical store or sent to you directly by the respective merchant/seller.
        </p>

        <p>Each merchant/store in the Inburg merchants’ network has its own policies regarding shipping and returns. However, most policies are according to market standards only. We strongly recommend you go through the policies of the respective stores before purchasing their products.
        </p>

        <p>
          In case you need to raise a support ticket against any purchase feel free to send an email to support@inburg.com
        </p>

      </div>

      <Footer />
    </div>
  );
};
