import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import Logo1 from "../assets/img/Brands_Logos/logo1.png";
import Logo2 from "../assets/img/Brands_Logos/logo2.png";
import Logo3 from "../assets/img/Brands_Logos/logo3.png";

import Logo5 from "../assets/img/Brands_Logos/logo5.svg";
import Logo6 from "../assets/img/Brands_Logos/logo6.png";
import Logo7 from "../assets/img/Brands_Logos/logo7.png";
import Logo8 from "../assets/img/Brands_Logos/logo8.png";
import Logo9 from "../assets/img/Brands_Logos/logo9.png";
import Logo10 from "../assets/img/Brands_Logos/logo10.png";
export const Brands = () => {
  return (
    <div className="Brands common_width">
      <h1>Brands on board</h1>

      <div className="Brands_Slider">
        <Splide
          options={{
            pagination: false,
            arrows: false,
            perPage: 3,
            gap: 20,
            breakpoints: {
              600: {
                perPage: 1,
              },
            },
          }}
        >
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo1} alt="1" className="long_img" />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo2} alt="1" className="long_img" />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo3} alt="1" className="long_img" />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo5} alt="1" className="long_img" />
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo6} alt="1" className="long_img" />
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo7} alt="1" className="long_img" />
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo8} alt="1" className="long_img" />
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo9} alt="1" className="long_img" />
            </div>
          </SplideSlide>{" "}
          <SplideSlide>
            <div className="img_wrapper">
              <img src={Logo10} alt="1" className="long_img" />
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};
