export const offline = {
  step1: {
    content: [
      {
        number: "1",
        text: "Customer grabbed the item & approaches billing counter of the network store for payment.",
      },
      {
        number: "2",
        text: "Scans the Inburg QR code using any QR code scanning app (including digital wallets).",
      },
    ],
  },

  step2: {
    content: [
      {
        number: "3",
        text: "QR code scan redirects customer to customized billing page (mobile responsive) of the network store",
      },
    ],
  },

  step3: {
    content: [
      {
        number: "3",
        text: "QR code scan redirects customer to customized billing page (mobile responsive) of the network store",
      },
    ],
  },
};
export const online = {
  step1: {
    content: [
      {
        number: "1",
        text: "Network stores have a code snippet installed/Inburg API Integration done with necessary permissions to read, write, update certain functionalities. e.g.",
      },
      {
        number: "",
        text: "get live order status.",
      },
      {
        number: "",
        text: "to render reward notification and coupon customization option for the current customer on than you page after successful payment",
      },
    ],
  },
  step2: {
    content: [
      {
        number: "2",
        text: "Customers make a purchase from Store A and   lands to thank you page after successful payment Order successful status triggersInburg to render/serve reward notice to the customer along with nstructionsto customize coupon",
      },
    ],
  },

  step3: {
    content: [
      {
        number: "3",
        text: "Customers enter their mobile number, select a coupon category, brand, available coupon denomination, confirm selection, click submit and they get the coupon sent to their mobile number via text message",
      },
    ],
  },
};
