import React from "react";

export const News = ({ img, name, placement, area, quote }) => {
  return (
    <div className="NewsReview">
      <img src={img} alt="" />
      <h1>{name}</h1>
      <p>{quote}</p>
      <span className="placement">{placement}</span>
      <span>{area}</span>
    </div>
  );
};
