import React from "react";
import { News } from "./News";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import anand from "../assets/img/anand.jpg";
import Arjun from "../assets/img/Arjun Vaidya.jpeg";
import Ashish from "../assets/img/Ashish Arya.png";
import Rohit from "../assets/img/Rohit Nandwani.jpeg";

export const GetNews = () => {
  return (
    <div className="GetNews">
      <div className="common_width">
        <h1 className="">Talk of the town</h1>
      </div>

      <div className="GetNewsSliderArea">
        <Splide
          options={{
            arrows: false,

            perPage: 1,
            perMove: 1,
          }}
        >
          <SplideSlide>
            <News
              img={anand}
              name="Anand Narang"
              placement="Vice President - Marketing"
              area="Bata India Limited"
              quote="“Great Idea. Worth trying!”"
            />
          </SplideSlide>
          <SplideSlide>
            <News
              img={Arjun}
              name="Arjun Vaidya"
              placement="Founder at Dr Vaidyas"
              area="VC at Verlinvest"
              quote="“Omni channel is the new buzzword and problem that we are all trying to solve. If this solution is able to execute - it presents a very exciting online to offline customer engagement option. O2O in my opinion is the future!”
              "
            />
          </SplideSlide>
          <SplideSlide>
            <News
              img={Ashish}
              name="Ashish Arya"
              placement="B2B Partnership Leader"
              area="Decathlon Sports India"
              quote="“The concept is amazing to have personalised coupons for everyone. It's a unique and patent-pending idea which will allow customers to make informed decisions within various brand segments.”"
            />
          </SplideSlide>

          <SplideSlide>
            <News
              img={Rohit}
              name="Rohit Nandwani"
              placement="COO, Hammer Lifestyle"
              area=""
              quote="“We  really liked the idea. This is innovational and a novel concept. This will definitely provide a better experience to our customers and help us to capture a similar set of customers as well. Looking forward to working with your brand.”
              "
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};
