import React, { useState } from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import axios from "axios"
import swal from 'sweetalert';

export const Contact = () => {
  // const navigate = useNavigate();
  const [contant, setContact] = useState({
    name: "",
    email: "",
    department: "technical",
    message: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact({ ...contant, [name]: value });
  };

  const submitHandle = (event) => {
    event.preventDefault();

    axios.post('https://api.inburg.com/inbug-contact', {
      name: contant.name,
      email: contant.email,
      department: contant.department,
      message: contant.message
    })
      .then(function (response) {
        if (response.data.status === 1) {
          swal({
            title: "Your request has been submitted.",
            text: " We will get back to you shortly.",
            type: "success",
            icon: "success",
            timer: 3000,
            button: false,
          })
          setContact({
            name: "",
            email: "",
            department: "technical",
            message: ""
          })
          // navigate('/')
        } else {
          console.log('Fail')
          swal({
            title: "Your request has not submitted.",
            type: "error",
            icon: "error",
            timer: 3000,
            button: false,
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  return (
    <>

      <div className="Privacy">
        <Header />

        <form action="" className="contact_form" onSubmit={submitHandle}>

          <div className="input_box">
            <label htmlFor="name">Name</label>
            <input id="name" type="text" placeholder="Name" value={contant.name} name="name" onChange={handleChange} required />
          </div>
          <div className="input_box">
            <label htmlFor="email">Email</label>
            <input id="email" type="text" placeholder="Email" value={contant.email} name="email" onChange={handleChange} required />
          </div>
          <div className="input_box">
            <label htmlFor="department">Select Department</label>
            <select name="department" id="department" onChange={handleChange}>
              <option value="technical">Technical</option>
              <option value="sales">Sales</option>
              <option value="merchant">Merchant Support</option>
              <option value="customer">Customer Support</option>
            </select>
          </div>
          <div className="input_box">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" value={contant.message} placeholder="Message" onChange={handleChange}></textarea>
          </div>
          <div className="input_box">
            <button type="submit">Submit</button>
          </div>


        </form>

        <div className="common_width contact-content">
          <h1>Contact Us</h1>
          <p className="">Inburg India Private Limited</p>
          <p className="address">203, 2-A/3, S/F Front Side, Asaf Ali Road,<br />Old Turkman Gate, New Delhi- 110002</p>
          <p className="">Don't hesitate to contact us if you have any questions</p>
          Via Email: <a href="mailto:hello@Inburg.com">hello@Inburg.com</a>
        </div>

        <Footer />
      </div>
    </>
  );
};
