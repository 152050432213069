import React from "react";
import { ServiceCard } from "./ServiceCard";
import signup from "../assets/img/signup.svg";
import register_store from "../assets/img/register_store.svg";
import coupons from "../assets/img/coupons.svg";
import create_inburg from "../assets/img/create_inburg.svg";
export const EasyServices = () => {
  return (
    <div className="EasyServices common_width">
      <h1>Onboarding</h1>

      <div className="service_cards">
        <ServiceCard
          img={signup}
          heading="Sign up & Join the network"
          text=""
        />
        <ServiceCard
          img={register_store}
          heading="Register your store"
          text="Get a unique
          Inburg QR code
          for your store. Get RestAPIs
          for e-commerce
          integration"
        />
        <ServiceCard
          img={coupons}
          heading="Define coupons denomination"
          text=""
        />
        <ServiceCard
          img={create_inburg}
          heading="Allow Inburg to
          create, issue
          and manage
          coupons on
          your behal"
          text=""
        />
      </div>
    </div>
  );
};
